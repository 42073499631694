@font-face {
	font-family: "Filson Soft W03";
	src: url("/assets/jamba/fonts/fc2a1dd0-9e0b-4771-9ad1-c44d9f4a89ab.eot?#iefix");
	src: url("/assets/jamba/fonts/fc2a1dd0-9e0b-4771-9ad1-c44d9f4a89ab.eot?#iefix") format("eot"), url("/assets/jamba/fonts/67243edf-db55-4c1d-92e2-ee4c33fa633b.woff2") format("woff2"), url("/assets/jamba/fonts/de0124a9-743a-4757-bfff-c509c5970ee7.woff") format("woff"), url("/assets/jamba/fonts/7dc9ee0c-f8e4-4631-843b-6153bccac5e5.ttf") format("truetype");
	font-weight: 400;
}

@font-face {
	font-family: "Filson Soft W03";
	src: url("/assets/jamba/fonts/652ef45f-6fb7-4751-8028-76107d36a0e9.eot?#iefix");
	src: url("/assets/jamba/fonts/652ef45f-6fb7-4751-8028-76107d36a0e9.eot?#iefix") format("eot"), url("/assets/jamba/fonts/6d6a8331-b28c-4421-89df-2b3d63d5dfd6.woff2") format("woff2"), url("/assets/jamba/fonts/70f59124-bb45-41a8-946b-425b647ccfb0.woff") format("woff"), url("/assets/jamba/fonts/8ff2297c-392c-4f7e-8c0c-a02071488115.ttf") format("truetype");
	font-weight: 500;
}

@font-face {
	font-family: "Filson Soft W03";
	src: url("/assets/jamba/fonts/84329372-da16-46bb-ba35-2f269957af88.eot?#iefix");
	src: url("/assets/jamba/fonts/84329372-da16-46bb-ba35-2f269957af88.eot?#iefix") format("eot"), url("/assets/jamba/fonts/2357f2d2-9956-4320-a9f2-b8e9d0919130.woff2") format("woff2"), url("/assets/jamba/fonts/43d726ff-b2d5-42d5-8a94-f3f70b12625f.woff") format("woff"), url("/assets/jamba/fonts/29f28e26-af2b-4163-b0c4-b61c2d7e54f4.ttf") format("truetype");
	font-weight: 700;
}

@import 'shared/variables';

.jamba-theme,
.csjamaun-theme {
	@import "shared/typography";
	@import "shared/buttons";
	@import 'shared/footer';
	@import "shared/header";

	@import "components/hero";
	// @import "components/button-list";
	@import "components/card";
	// @import "components/disclaimer";
	// @import "components/spotlight";
}

.csjamaun-theme {
	.main-header {
		.header-content {
			.logo-wrapper {
				a {
					.logo {
						max-width: 100%;
					}
				}
			}
		}
	}

	.hero-component {
		p {
			&:first-of-type {
				margin-bottom: rem(10px);
			}
		}

		.content-wrapper {
			.note {
				font-size: rem(24px);
				font-weight: strong;
			}
		}
	}

	h3 {
		font-size: rem(30px);
		font-weight: bold;
		margin-bottom: rem(20px);
	}
}

.fuelrewards-theme {
	.hero-component {
		background-size: 100%;
		background-repeat: no-repeat;
		background-position: center top;
		min-height: fit-content;
		height: 578px;

		@media screen and (max-width: 1920px) {
			height: 450px;
		}

		@media screen and (max-width: 1440px) {
			height: 350px;
		}

		@media screen and (max-width: 1024px) {
			height: 300px;
		}

		@media screen and (max-width: 767px) {
			height: 120px;
			margin-bottom: 0;
		}
	}
}

.fuelrewardslto-theme {
	.hero-component {
		background-size: 100%;
		background-repeat: no-repeat;
		background-position: center top;
		min-height: fit-content;
		height: 500px;

		@media screen and (max-width: 1024px) {
			height: 300px;
		}

		@media screen and (max-width: 767px) {
			height: 160px;
			margin-bottom: 0;
		}
	}
}

.jamba-theme {
	&.aarp-theme {
		font-family: $primaryType;
		
		h3 {
			font-size: rem(22px);
			font-weight: 700;
			color: var(--headingText);
		}
	}

	&.link-account-theme {
		font-family: $primaryType;
	}

	&.success-theme {
		font-family: $primaryType;

		.section-subtitle {
			font-size: rem(20px);
			text-align: center;
		}

		ol {
			font-family: $primaryType;
		}
	}

	&.fuelrewardslto-theme, &.fuelrewards-theme {
		font-family: $primaryType;

		h1 {
			font-family: $primaryType;
			font-size: rem(40px);
		}

		@media screen and (max-width: 768px) {
			h1 {
				font-size: rem(36px);
			}

			.section-title {
				font-size: rem(30px);
			}
		}
	}

	&.tmobiletuesdayssept3-theme {
		font-family: $primaryType;

		h1 {
			font-family: $primaryType;
		}

		h3 {
			font-weight: bold;
			font-size: rem(20px);
		}
	}
}
